import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/receipts', config);
}

const student = async (config) => {
    return FinanceApi.get('/receipts/student', config);
}
const get = async (code) => {
    return FinanceApi.get('/receipts/' + code);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/receipts/' + data.id, data);
    }
    return FinanceApi.post('/receipts', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/receipts/' + id, formData);
}

const del = async (id) => {
    return FinanceApi.delete('/receipts/' + id);
}

const download = async (id) => {
    return FinanceApi.get('/receipts/' + id + '/download', {responseType: 'arraybuffer'});
}

export default {
    getAll,
    get,
    store,
    update,
    del,
    download,
    student
}
