<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('bank_receipts')" @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('bank_receipts')" @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.start_date">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.end_date">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox
                                code="receipt_statuses"
                                v-model="datatable.queryParams.filter.status"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
            <CommonModal id="receiptModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('edit') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="receiptForm">
                        <div class="row" v-if="receiptForm">
                            <div class="col-12">
                                <ValidationProvider name="status" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('status') }}</label>
                                        <parameter-selectbox
                                            code="receipt_statuses"
                                            :validate-error="errors[0]"
                                            v-model="receiptForm.status"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules=""
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-textarea
                                            v-model="receiptForm.explanation"></b-form-textarea>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>


                            <div class="col-12">
                                <b-button type="submit" @click="save" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import ReceiptService from "@/services/ReceiptService";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";
import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox.vue";
import PaymentYearSelectbox from "@/components/interactive-fields/PaymentYearSelectbox.vue";

export default {
    components: {
        PaymentYearSelectbox,
        YearsSelectbox,
        BankSelectbox,
        PaymentTypesSelectbox,
        CurrencySelectbox,
        MultiSelectbox,
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox,
        CommonModal,
        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t("bank_receipts"),
        };
    },
    data() {
        return {
            receiptForm: null,
            terminalOptions: [],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass:
                            "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("download"),
                                class: "ri-file-download-line",
                                permission: "receipt_download",
                                callback: (row) => {
                                    this.download(row);
                                },
                            },
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "receipt_update",
                                callback: (row) => {
                                    this.showReceipt(row);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "receipt_delete",
                                callback: (row) => {
                                    this.deleteReceipt(row.id);
                                },
                            }
                        ],
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: 'academic_year',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: 'number',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("status"),
                        field: this.getLocaleField('status_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("created_at"),
                        field: 'created_at',
                        formatFn: this.toLocaleDateTime,
                        thClass: 'width-150',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        createFormShow() {
            this.formId = null
            this.receiptForm = {};
            this.$bvModal.show('receiptModal');
        },
        callService(config) {
            return ReceiptService.getAll(config)
        },
        getRows() {
            this.$refs.datatable.getRows();
        },
        async save() {
            const isValid = await this.$refs.receiptForm.validate();
            if (isValid) {
                if (this.receiptForm.id > 0) {

                    ReceiptService.update(this.receiptForm.id, {status: this.receiptForm.status, explanation: this.receiptForm.explanation})
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('receiptModal');
                            this.getRows();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.receiptForm);
                        });
                }
            }
        },
        showReceipt(row = {}) {
            this.$bvModal.show('receiptModal');
            this.receiptForm = row;
        },
        deleteReceipt(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        ReceiptService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getRows();
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        },
        download(row){
            ReceiptService.download(row.id).then(response=>{
                this._downloadFile(response, row.file.name + '-' + row.file.file_name)
            })
        },
    }
};
</script>

